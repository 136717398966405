import * as React from "react"
import * as styles from "./pipedrive-form.module.scss"
import Container from "react-bootstrap/Container"

const PipeDriveForm = () => {
  return (
    <Container fluid className={styles.textContainer}>
      <div
        className="pipedriveWebForms"
        data-pd-webforms="https://webforms.pipedrive.com/f/bYWhB0lGIVTr7SCooH0URssjsb5mc0Qjanwm04t8ouRt51d0MIurYnV22G23hTok3p"
      ></div>
    </Container>
  )
}

export default PipeDriveForm
