import * as React from "react"
import { graphql, HeadProps, PageProps } from "gatsby"
import Layout from "../components/layout/layout"
import Hero from "../components/hero/hero"
import PipeDriveForm from "../components/pipedrive-form/pipedrive-form"
import Seo from "../components/seo/seo"
import { paragraphResolver } from "../helpers/paragraphsResolver"
import {
  DrupalNode,
  DrupalFormattedText,
  DrupalMediaImage,
  DrupalParagraph,
} from "../types/drupal"

interface NodePageProps extends DrupalNode {
  field_hero_text: DrupalFormattedText
  relationships: {
    field_background_image?: DrupalMediaImage
    field_components?: DrupalParagraph[]
  }
}

interface DataProps {
  nodePage: NodePageProps
}

const PageTemplate = ({ data: { nodePage } }: PageProps<DataProps>) => {
  const variant = nodePage.field_hero_text ? "basic" : "work"
  const media = nodePage.relationships?.field_background_image
  const components = nodePage.relationships?.field_components

  return (
    <Layout>
      <Hero
        variant={variant}
        title={nodePage.title}
        body={nodePage.field_hero_text?.processed}
        level={1}
        media={media}
      />
      {components &&
        components.map(component => {
          return (
            <React.Fragment key={component.id}>
              {paragraphResolver({ node: component })}
            </React.Fragment>
          )
        })}
      {nodePage.title == "Contact Us" && <PipeDriveForm></PipeDriveForm>}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    nodePage(id: { eq: $id }) {
      id
      title
      field_short_title
      field_hero_text {
        value
        processed
      }
      path {
        alias
      }
      langcode
      ...Metatag
      relationships {
        field_background_image {
          field_image {
            alt
          }
          relationships {
            field_image {
              publicUrl
              gatsbyImage(width: 500, layout: FIXED, quality: 100)
            }
          }
        }
        field_components {
          __typename
          ...ParagraphText
          ...Paragraph3ColumnText
          ...ParagraphLogoGroup
          ...ParagraphCardList
          ...ParagraphCardListStacked
          ...ParagraphSideBySide
          ...ParagraphIconSpacer
          ...ParagraphImage
        }
      }
    }
  }
`

export default PageTemplate

export const Head = ({ data: { nodePage } }: HeadProps<DataProps>) => {
  return (
    <>
      <html lang={nodePage.langcode} />
      {nodePage?.metatag ? (
        <Seo metatag={nodePage.metatag} />
      ) : (
        <title>{nodePage.title}</title>
      )}
      <script src="https://webforms.pipedrive.com/f/loader"></script>
    </>
  )
}
